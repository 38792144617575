import styled from 'styled-components';

export const ContentPage = styled.div`
    padding: 32px 24px;
    margin: 0 auto;
    background: linear-gradient(0.85turn, #d7e9ef, #d7e9ef, #fff);

    .about-us-container {
    @media (min-width: 767px) {
    min-height: 600px;
    }
    }
    h1 {
        margin: 12px auto;
        position: relative;

        // &::after {
        //     display: inline-block;
        //     content: ' ';
        //     width: 100px;
        //     height: 4px;
        //     background: linear-gradient(90deg, rgba(38,98,236,1) 0%, rgba(61,150,239,1) 100%);
        //     position: absolute;
        //     bottom: -10px;
        //     left: 0;
        // }
    }

    h2 {
        font-size: 24px;
        margin-bottom: 12px;
        color: #4188a0;
    }

    p {
        font-size: 16px;
    }

    a {
        color: #2562EC;
    }
    .blog-section-wrapper {
    margin-bottom: 44px;
    }
    li {
    }
    
`

export const Divider = styled.div`
    border-top: 1px solid #eea640;
    margin: 32px auto;
    
`
