import styled from 'styled-components';

export const StyledFooter = styled.div`
    padding-top: 44px;
    padding-bottom: 24px;
    border-top: 1px solid #eee;
    background-color: #6bacc3;
    font-size: 12px;
    color: #000;
     @media (min-width: 560px) {
     font-size: 14px;
     }
      @media (min-width: 767px) {
     font-size: 18px;
     }
    & > {
    font-size: 24px;
    }
    
`

export const Copyright = styled.div`
    opacity: .8;
    font-weight: 300;
    margin-top: 12px;
    display: flex;
    color: #000;
    flex-direction: column;
    border-top: 1px solid #000;
    padding-top: 12px;
    font-size: 12px;
     @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      font-size: 16px;
       padding-top: 24px;
    }
     &.custom-container {
      @media (min-width: 7680px) {
     max-width: 960px;
     }
     @media (min-width: 1200px) {
     max-width: 1100px;
     }
    }
    
    a {
        color: #fff;
    }
    
`

export const FooterNavRow = styled.div`
    display: flex;
    flex-direction: column;
     @media (min-width: 460px) {
       flex-direction: row;
    }
    img {
    width: 200px;
    }
    &.custom-container {
    justify-content: right;
     @media (min-width: 1200px) {
     max-width: 1100px;
     }
    }
    
`

export const FooterNavCol = styled.div`
        &.sn-contact-us {
        margin-bottom: 24px;
        width: 38%;
        margin-right: 4rem;
        font-size: 18px;
        }
    @media (max-width: 460px) {
        &.sn-contact-us {
            width: 100% !important;
        }
    }
  @media (min-width: 1200px) {
        &.sn-contact-us {
        line-height: 2rem;
        }
    }
    line-height:1.2rem;

    & > div{
        padding: 4px 0;
    }

    h6{
        margin-bottom: 0;
        color: #fff;
        font-weight: bolder;
        margin-top: 8px;
         @media (min-width: 768px) {
             margin-bottom: 16px;
       }
       @media (min-width: 768px) {
            font-size: 1.3rem;
       }
    }

    a{
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        opacity: .8;
        @media (min-width: 768px) {
        font-size: 18px;
        }
    }
    .social-links-wrapper {
    display: flex;
    flex-direction: column;
    }
    
`
