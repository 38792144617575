import Header from '../components/header'
import { ContentPage, Divider } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import React from "react";
import {
    EventImg,
} from '../components/styles/Homepage.styled'


export const Community = () => {
    const { t } = useTranslation('translation');

    return (
        <>

            <Header />
            <ContentPage>
                <Container>
                  <div>
                      <h2><span>{t('community.title')}</span>: {t('community.article1.title1')}</h2>
                      <h4>{t('community.introduction')}</h4>
                      <p>{t('community.article1.intro1')}</p>
                      <ol>
                          <li><span className="fw-bold">{t('community.article1.text1')}:</span>&nbsp;<span>{t('community.article1.desc1')}</span></li>
                          <li><span className="fw-bold">{t('community.article1.text2')}:</span>&nbsp;<span>{t('community.article1.desc2')}</span></li>
                          <li><span className="fw-bold">{t('community.article1.text3')}:</span>&nbsp;<span>{t('community.article1.desc3')}</span></li>
                          <li><span className="fw-bold">{t('community.article1.text4')}:</span>&nbsp;<span>{t('community.article1.desc4')}</span></li>
                          <li><span className="fw-bold">{t('community.article1.text5')}:</span>&nbsp;<span>{t('community.article1.desc5')}</span></li>
                          <li><span className="fw-bold">{t('community.article1.text6')}:</span>&nbsp;<span>{t('community.article1.desc6')}</span></li>
                      </ol>
                      <h5><b>{t('community.conclusion')}</b></h5>
                      <p>{t('community.article1.conclusion1')}</p>
                  </div>
                    <EventImg>
                        <img src="/images/pexels-mart-production-7606074.jpg" alt="" />
                    </EventImg>
                    <Divider></Divider>
                    <div>
                        <h2><span>{t('community.title')}</span>: {t('community.article2.title1')}</h2>
                        <h4>{t('community.introduction')}</h4>
                        <p>{t('community.article2.intro1')}</p>
                        <ol>
                            <li><span className="fw-bold">{t('community.article2.text1')}:</span>&nbsp;<span>{t('community.article2.desc1')}</span></li>
                            <li><span className="fw-bold">{t('community.article2.text2')}:</span>&nbsp;<span>{t('community.article2.desc2')}</span></li>
                            <li><span className="fw-bold">{t('community.article2.text3')}:</span>&nbsp;<span>{t('community.article2.desc3')}</span></li>
                            <li><span className="fw-bold">{t('community.article2.text4')}:</span>&nbsp;<span>{t('community.article2.desc4')}</span></li>
                            <li><span className="fw-bold">{t('community.article2.text5')}:</span>&nbsp;<span>{t('community.article2.desc5')}</span></li>
                        </ol>
                        <h5><b>{t('community.conclusion')}</b></h5>
                        <p>{t('community.article2.conclusion1')}</p>
                    </div>
                    <EventImg>
                        <img src="/images/pexels-rdne-6182533.jpg" alt="" />
                    </EventImg>
                    <Divider></Divider>
                    <div>
                        <p>{t('community.article3.intro1')}</p>
                        <ol>
                            <b><li>{t('community.article3.text1')}:</li></b>
                            <ul>
                                <li>{t('community.article3.desc1')}</li>
                                <li>{t('community.article3.desc2')}</li>
                            </ul>
                            <b><li>{t('community.article3.text2')}:</li></b>
                            <ul>
                                <li>{t('community.article3.desc3')}</li>
                                <li>{t('community.article3.desc4')}</li>
                            </ul>
                            <b><li>{t('community.article3.text3')}:</li></b>
                            <ul>
                                <li>{t('community.article3.desc5')}</li>
                                <li>{t('community.article3.desc6')}</li>
                            </ul>
                            <b><li>{t('community.article3.text4')}:</li></b>
                            <ul>
                                <li>{t('community.article3.desc7')}</li>
                                <li>{t('community.article3.desc8')}</li>
                            </ul>
                            <b><li>{t('community.article3.text5')}:</li></b>
                            <ul>
                                <li>{t('community.article3.desc9')}</li>
                                <li>{t('community.article3.desc10')}</li>
                            </ul>
                            <b><li>{t('community.article3.text6')}:</li></b>
                            <ul>
                                <li>{t('community.article3.desc11')}</li>
                                <li>{t('community.article3.desc12')}</li>
                            </ul>
                            <b><li>{t('community.article3.text7')}:</li></b>
                            <ul>
                                <li>{t('community.article3.desc13')}</li>
                                <li>{t('community.article3.desc14')}</li>
                            </ul>
                            <b><li>{t('community.article3.text8')}:</li></b>
                            <ul>
                                <li>{t('community.article3.desc15')}</li>
                                <li>{t('community.article3.desc16')}</li>
                            </ul>
                            <b><li>{t('community.article3.text9')}:</li></b>
                            <ul>
                                <li>{t('community.article3.desc17')}</li>
                                <li>{t('community.article3.desc18')}</li>
                            </ul>
                            <b><li>{t('community.article3.text10')}:</li></b>
                            <ul>
                                <li>{t('community.article3.desc19')}</li>
                                <li>{t('community.article3.desc20')}</li>
                            </ul>
                        </ol>
                        <p>{t('community.article3.conclusion1')}</p>
                    </div>
                    <EventImg>
                        <img src="/images/pexels-vlada-karpovich-4050388.jpg" alt="" />
                    </EventImg>
                </Container>
            </ContentPage>
        </>
    )
}
