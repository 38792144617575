import { Link } from 'react-router-dom'
import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
  const { t } = useTranslation('translation');
  return (
    <>
      <Header />
      <ContentPage>
        <Container>
          <h1>{t('privacy_policy.title')}</h1>
          <p>{t('privacy_policy.intro')}</p>
          <h2>{t('privacy_policy.information_we_collect.title')}</h2>
          <p>{t('privacy_policy.information_we_collect.description')}</p>
          <p>
            <b>{t('privacy_policy.information_we_collect.pii.title')}</b> {t('privacy_policy.information_we_collect.pii.description')}
          </p>
          <ul>
            <li>{t('privacy_policy.information_we_collect.personal_information.0')}</li>
            <li>{t('privacy_policy.information_we_collect.personal_information.1')}</li>
            <li>{t('privacy_policy.information_we_collect.personal_information.2')}</li>
            <li>{t('privacy_policy.information_we_collect.personal_information.3')}</li>
            <li>{t('privacy_policy.information_we_collect.personal_information.4')}</li>
            <li>{t('privacy_policy.information_we_collect.personal_information.5')}</li>
            <li>{t('privacy_policy.information_we_collect.personal_information.6')}</li>
          </ul><p>
            <b>(b) {t('privacy_policy.information_we_collect.general_information.title')}</b> {t('privacy_policy.information_we_collect.general_information.description')}
          </p>
          <p>
            <b>(c) {t('privacy_policy.information_we_collect.social_media_related_content.title')}</b>
            <br />
            {t('privacy_policy.information_we_collect.social_media_related_content.description')}
          </p>
          <h2>{t('privacy_policy.how_we_use_PII.title')}</h2>
          <p>{t('privacy_policy.how_we_use_PII.description')}</p>
          <ul>
            <li>{t('privacy_policy.how_we_use_PII.items.0')}</li>
            <li>{t('privacy_policy.how_we_use_PII.items.1')}</li>
            <li>{t('privacy_policy.how_we_use_PII.items.2')}</li>
            <li>{t('privacy_policy.how_we_use_PII.items.3')}</li>
            <li>{t('privacy_policy.how_we_use_PII.items.4')}</li>
            <li>{t('privacy_policy.how_we_use_PII.items.5')}{' '}
              <Link to="/terms_of_service">{t('privacy_policy.how_we_use_PII.terms_of_service')}</Link></li>
          </ul>
          <p>{t('privacy_policy.how_we_use_PII.additional_info')}</p>
          <p>{t('privacy_policy.how_we_use_PII.anonymization_info')}</p>

          <h2>{t('privacy_policy.how_we_share_information.title')}</h2>
          <p>{t('privacy_policy.how_we_share_information.description')}</p>
          <p>{t('privacy_policy.how_we_share_information.reps')}</p>
          <p>{t('privacy_policy.how_we_share_information.customize')}</p>
          <p>{t('privacy_policy.how_we_share_information.disclosure')}</p>

          <h2>{t('privacy_policy.how_we_protect_information.title')}</h2>
          <p>{t('privacy_policy.how_we_protect_information.description')}</p>
          <p>{t('privacy_policy.how_we_protect_information.security_warning')}</p>

          <h2>{t('privacy_policy.how_long_we_retain_your_data.title')}</h2>
          <p>{t('privacy_policy.how_long_we_retain_your_data.description')}</p>

          <h2>{t('privacy_policy.what_rights_you_have_over_your_data.title')}</h2>
          <p>{t('privacy_policy.what_rights_you_have_over_your_data.description')}</p>
          
          <h2>{t('privacy_policy.third_party_links.title')}</h2>
          <p>{t('privacy_policy.third_party_links.content')}</p>

          <h2>{t('privacy_policy.tracking_technology.title')}</h2>
          <p>{t('privacy_policy.tracking_technology.content')}</p>

          <h2>{t('privacy_policy.children.title')}</h2>
          <p>{t('privacy_policy.children.content')}</p>

          <h2>{t('privacy_policy.california_residents.title')}</h2>
          <p>{t('privacy_policy.california_residents.content')}</p>
          <p>{t('privacy_policy.california_residents.anonymity')}</p>

          <h2>{t('privacy_policy.changes.title')}</h2>
          <p>{t('privacy_policy.changes.content')}</p>
        </Container>
      </ContentPage>
    </>
  )
}
