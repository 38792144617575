import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'

export const LetterOfAuthorization = () => {
    return (
        <>
            <Header />
            <ContentPage>
                <Container>
                    <h1>Letter of Authorization</h1>
                </Container>
            </ContentPage>
        </>
    )
}
