import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { LoadingRow } from '../components/loading-row';
import { Dropdown } from 'react-bootstrap';
import type { TermFilterProps } from '../types';
interface TermOption {
    label: string;
    value: string;
}

export const TermFilter: React.FC<TermFilterProps> = ({
    initializeFilter,
    loading,
    handleClick,
    availableTerms,
    terms
}) => {
    const { t } = useTranslation('plan');
    const [ termsOptions, setTermsOptions ] = useState<Array<TermOption>|undefined>(undefined);
    const [ selectedTerm, setSelectedTerm ] = useState<TermOption|undefined>(undefined);
    
    useEffect(() => {
        let newTermsOptions = [],
            tier1:Array<string> = [],
            tier2:Array<string> = [],
            tier3:Array<string> = [];

        terms.forEach(val => {
            const month = parseInt(val);
            if (month === 0) return true;
            if (month > 0 && month < 12) tier1.push(`${month}`);
            if (month >= 12 && month < 24) tier2.push(`${month}`);
            if (month >= 24) tier3.push(`${month}`);
            newTermsOptions.push({ label: `${month} ${t('Month')}`, value: `${month}` })
        });

        if (tier3.length > 0) newTermsOptions.unshift({ label: t('More Than 2 Years'), value: `${tier3.join(',')},` });
        if (tier2.length > 0) newTermsOptions.unshift({ label: t('Between 1-2 Years'), value: `${tier2.join(',')},` });
        if (tier1.length > 0) newTermsOptions.unshift({ label: t('Less Than 1 Year'), value: `${tier1.join(',')},` });
        newTermsOptions.unshift({ label: t('Show All'), value: '0' });

        setTermsOptions(newTermsOptions);
        if (!selectedTerm) {
            setSelectedTerm(newTermsOptions[0]);
        }
        else {
            // for translation purpose
            for (let i in newTermsOptions) {
                const option = newTermsOptions[i as any];
                if (option.value === selectedTerm.value) {
                    setSelectedTerm(option);
                    break;
                }
            }
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [terms, availableTerms])

    useEffect(() => {
        if (initializeFilter && selectedTerm && termsOptions) {
            setSelectedTerm(termsOptions[0]);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initializeFilter])

    if (initializeFilter || !termsOptions) return <LoadingRow size="sm" />

    return (
        <div className="plans-filter-options">
            <Dropdown className="plans-filter-dd">
                <Dropdown.Toggle variant="light" disabled={loading}>
                    {t('Plan Length')}: {(selectedTerm as any).label}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        termsOptions.map((val: any, idx) => (
                            <Dropdown.Item
                                eventKey={idx}
                                key={idx}
                                onClick={() => {
                                    handleClick(val.value);
                                    setSelectedTerm(val);
                                }}
                                active={selectedTerm && (selectedTerm as any).value === val.value}
                                disabled={
                                    availableTerms.indexOf(val.value) < 0 && val.value !== '0' && val.value.indexOf(',') === -1
                                }
                            >{val.label}</Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
