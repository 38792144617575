/*
 * APIs are powered by PowerLego
 * Website: https://www.powerlego.com/
 */

import { API_URL, ACC_KEY, SIDEBYSIDE_API_URL } from '../config';


export const fetchMailChimp = async (name,email) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
    const formdata = new FormData();
    formdata.append("id", 'e20978a623');
    formdata.append("first_name", name);
    formdata.append("email_address", email);
    
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v2/MailChimp/add_member`

    const response = await fetch(url, requestOptions)
    return response.json()
}

/*
 * Address Validator
 * Use cases:        https://www.powerlego.com/cases/address_validator
 * Postman examples: https://documenter.getpostman.com/view/4831254/SzKYPH3c#ca1bdf1f-2318-4fed-aaeb-88708d7c34eb
 */
export const fetchAddress = async (val) => {
    const string = val.toString().replace(/#/, '')

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
    
    const formdata = new FormData();
    formdata.append("term", string);
    
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v1/address_validator`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const newAgreement = async (esiid, meterNumber, certificateNumber, email, forwardEmail) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
    
    const formdata = new FormData();
    formdata.append("esiid", esiid);
    formdata.append("meter_number", meterNumber);
    formdata.append("certificate_number", certificateNumber);
    formdata.append("email", email);
    formdata.append("email_forward", forwardEmail);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v2/smt/new_agreement`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getAgreement = async (consentId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
    
    const formdata = new FormData();
    formdata.append("consent_id", consentId);
    formdata.append("vip", "1");

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v2/smt/get_agreement`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchRetailElectricProviders = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };

    const url = `${API_URL}v2/smt/retail_electric_providers`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchUtility = async (zipcode) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("zipcode", zipcode);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v2/utility/get`

    const response = await fetch(url, requestOptions)
    return response.json()
}

/*
 * Usage Estimator
 * Use cases:        https://www.powerlego.com/cases/usage_estimator
 * Postman examples: https://documenter.getpostman.com/view/4831254/SzKYPH3c#bd865131-a3b9-4b62-8254-8abc47380670
 */
export const fetchEstUsage = async (address, city, state, zipcode) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("address1", address);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("zipcode", zipcode);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v1/usage_estimator`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchIntervalUsage = async (esiid, consentId, startDate, endDate) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("consent_id", consentId);
    formdata.append("esiid", esiid);
    formdata.append("start_date", startDate);
    formdata.append("end_date", endDate);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v2/smt/interval15`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchMonthlyUsage = async (esiid, consentId, startDate, endDate) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("consent_id", consentId);
    formdata.append("esiid", esiid);
    formdata.append("start_date", startDate);
    formdata.append("end_date", endDate);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v2/smt/monthly`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchDailyUsage = async (esiid, consentId, startDate, endDate) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("consent_id", consentId);
    formdata.append("esiid", esiid);
    formdata.append("start_date", startDate);
    formdata.append("end_date", endDate);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v2/smt/daily`

    const response = await fetch(url, requestOptions)
    return response.json()
}

const getPlanFormData = (type, usage, zipcode, filter, utilityCode) => {
    const formdata = new FormData();
    formdata.append("zipcode", zipcode);
    formdata.append("utility_code", utilityCode);
    
    if (usage) {
        if (type === 'normal') {
            for (let u in usage) {
                formdata.append(`usage[monthly][${u}]`, usage[u]);
            }
            formdata.append('filter[type]', 'normal');
        }
        else {
            for (let u in usage) {
                const data = usage[u];
                for (let i in data) {
                    if (i === 'free') continue;
                    formdata.append(`usage[monthly][${u}]`, usage[u][i]);
                }
            }
            formdata.append('filter[type]', `free_${type}`);
        }
    }
    else {
        if (type === 'normal') {
            formdata.append('filter[type]', 'normal');
        }
        else {
            formdata.append('filter[type]', 'tou');
        }
    }

    for (let f in filter) {
        if (f === 'provider_id') {
            filter.provider_id.forEach((val, idx) => {
                formdata.append(`filter[provider_id][${idx}]`, val);
            })
        }
        else if (f === 'term') {
            filter.term.forEach((val, idx) => {
                if (!val) return true;
                formdata.append(`filter[term][${idx}]`, val);
            })
        }
        else if (f === 'prepaid') {
            if (filter.prepaid !== 'all') {
                formdata.append(`filter[prepaid]`, filter.prepaid === 'true');
            }
        }
        else {
            if (f !== 'type') {
                if (filter[f]) formdata.append(`filter[${f}]`, filter[f]);
            }
        }
    }
    return formdata
}

export const fetchPlans = async (type, usage, zipcode, filter, utilityCode) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

        const formdata = getPlanFormData(type, usage, zipcode, filter, utilityCode)

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        // const url = `${API_URL}v2/commission/plan`
        const url = `${API_URL}v2/suconexion/plan`

        const response = await fetch(url, requestOptions)
        return response.json()
    }
    catch(err) {
        console.log(err)
    }
}

export const fetchFilterOptions = async (type, usage, zipcode, filter, utilityCode) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

        const formdata = getPlanFormData(type, usage, zipcode, filter, utilityCode)

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        // const url = `${API_URL}v2/commission/filter`
        const url = `${API_URL}v2/suconexion/filter`

        const response = await fetch(url, requestOptions)
        return response.json()
    }
    catch(err) {
        console.log(err)
    }
}

export const fetchPremiseDetail = async (zipcode, address) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("zipcode", zipcode);
    formdata.append("address", address);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v2/premise/detail`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchRepList = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };

    const url = `${API_URL}v1/rep_list`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getMyAccountInfo = async (username, password, rep) => {
    const myHeaders = new Headers();
    
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
    
    const formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);
    formdata.append("rep", rep);
    formdata.append("method", '0');
    
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    
    const url = `${API_URL}v1/rep_usage`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getPlanAvgPrice = async (address, zipcode) => {
    const url = 'https://api.eiqhome.com/plan/average_price';

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        },
        body: `address=${address}&zipcode=${zipcode}`
    })

    return response.json()
}

export const fetchSidebySidePlans = async (
    planId, rate, zipcode, utilityCode, usages, planType, terms
) => {
    const requestData = `plan_id=${planId}&rate=${rate}&zipcode=${zipcode}&utility_code=${utilityCode}&filter[type]=${planType}`;
    const usageData = [];
    const termsData = [];

    for (let i in usages) {
        usageData.push(`usage[monthly][${i}]=${usages[i]}`);
    }

    for (let j in terms) {
        if (terms[j]) {
            termsData.push(`term[${j}]=${terms[j]}`);
        }
    }

    try {
        const res = await fetch(`${SIDEBYSIDE_API_URL}/ComparePrices/index`,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                body: `${requestData}&${usageData.join('&')}${termsData.length > 0 ? `&${termsData.join('&')}` : ''}`
            }
        )
        return res.json()
    }
    catch(err) {
        console.error(err);
    }
}
