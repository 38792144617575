import type { PlanProps } from '../../types';
import { useTranslation } from "react-i18next";
import { CompareTableWrapper } from '../styles/Plan.styled';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Button } from '../styles/Button.styled';
import { getLogoSrc } from '../../util';

export const CompareModal = ({ show, handleCloseModal, comparePlans, handleClickEnroll }: {
    show: boolean;
    handleCloseModal: Function;
    comparePlans: PlanProps[];
    handleClickEnroll: Function;
}) => {
    const { t } = useTranslation('plan');
    const fields = [
        {key: 'provider_id', label: ''},
        {key: 'plan_name', label: t('Plan Name')},
        {key: 'contract_term', label: t('Plan Length')},
        {key: 'base_rate', label: t('Plan Base Rate')},
        {key: 'total', label: t('Total Est. Cost')},
        {key: 'avg_monthly_cost', label: t('Est. Avg. Monthly Cost')},
        {key: 'rate', label: t('Total Est. Rate/kWh')},
        {key: 'cancel_fee', label: t('Cancellation Fee')},
        {key: 'renewable', label: `${t('Renewable')} %`},
        {key: 'is_prepaid', label: t('Prepaid Plan')},
        {key: 'is_tou_plan', label: t('Free Energy Plan')},
        {key: 'enroll_btn', label: ''}
    ];

    return (
        <Modal size="lg" show={show} onHide={() => {handleCloseModal()}}>
            <Modal.Header closeButton>
                <Modal.Title>{t('Compare Plans')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CompareTableWrapper>
                    <table width="100%">
                        <tbody>
                        {
                            fields.map((field, fieldIdx) => (
                                <tr key={fieldIdx}>
                                    <td className="th">{field.label}</td>
                                    {
                                        comparePlans.map((plan: any, planIdx) => {
                                            const { key } = field;
                                            let _html = <span>-</span>;
                                            if (key === 'provider_id') {
                                                _html = <img src={getLogoSrc(plan[key])} width="100" alt="" />
                                            }
                                            else if (key === 'base_rate') {
                                                _html = <span>{(parseFloat(plan[key]) * 100).toFixed(2)}¢</span>;
                                            }
                                            else if (key === 'total') {
                                                _html = <div>
                                                        <div>{plan.hide_price ? 'N/A' : `${plan[key]}`}</div>
                                                        <div><i>{t('Based on')} {plan.hide_price ? 'N/A ' : <NumberFormat value={plan.total_usage} displayType={'text'} thousandSeparator={true} />}</i></div>
                                                    </div>
                                            }
                                            else if (key === 'avg_monthly_cost') {
                                                _html = plan.hide_price ? <span>N/A</span> : <NumberFormat value={(plan.total / parseInt(plan.contract_term)).toFixed(2)} prefix={'$'} displayType={'text'} thousandSeparator={true} />
                                            }
                                            else if (key === 'renewable') {
                                                _html = <span>{plan[key]}%</span>
                                            }
                                            else if (key === 'is_prepaid') {
                                                _html = <span>{plan[key] === '1' ? 'Yes' : 'No'}</span>
                                            }
                                            else if (key === 'is_tou_plan') {
                                                _html = <span>{plan[key] ? 'Yes' : 'No'}</span>
                                            }
                                            else if (key === 'enroll_btn') {
                                                _html = <Button $md onClick={() => {
                                                    if (plan.type === 'market') {
                                                        window.open(plan.enroll_url, '_blank');
                                                    }
                                                    else {
                                                        localStorage.setItem('ts_plan', JSON.stringify(plan));
                                                        handleClickEnroll(plan.plan_id);
                                                    }
                                                }}>{t('Enroll Now')}</Button>
                                            }
                                            else if (key === 'cancel_fee') {
                                                _html = <span>{plan[key]}{plan.cancel_fee_type === 'PerMonth' ? ` (${t('Per Remaining Month')})` : ''}</span>;
                                            }
                                            else {
                                                _html = <span>{plan[key]}</span>;
                                            }
                                            return (
                                                <td key={planIdx}>{_html}</td>
                                            )
                                        })
                                    }
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </CompareTableWrapper>
            </Modal.Body>
        </Modal>
    )
}
