import { API_URL, ACC_KEY } from '../config'

export const saveEnrollment = async (enrollmentId, restFields, sign) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    if (enrollmentId) formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)
    for (let i in restFields) {
        formdata.append(i, restFields[i])
    }
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/suconexion/save`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getEnrollmentRecord = async (enrollmentId, sign) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/suconexion/retrieve`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const confirmEnrollment = async (enrollmentId, specialPro, sign) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)
    for (const key in specialPro) {
        if (specialPro[key]) formdata.append(key, specialPro[key])
    }

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/suconexion/submit`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchPayment = async (sign, enrollmentId, confirm_code ) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    formdata.append('sign', sign)
    formdata.append('confirm_code', confirm_code)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/suconexion/payment`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const confirmEnrollmentInterval = async (enrollmentId, confirm_code, sign) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)
    confirm_code && formdata.append('confirm_code', confirm_code)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/suconexion/confirm`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getEnrollmentInputs = async (providerId) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('provider_id', providerId)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/suconexion/input`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getBlackoutDate = async (product, zipcode, utility_code) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('product', product)
    formdata.append('zipcode', zipcode)
    formdata.append('utility_code', utility_code)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/suconexion/blackout_date`

    const response = await fetch(url, requestOptions)
    return response.json()
}
