import Header from '../components/header'
import {ContentPage, Divider} from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import React from "react";
import {
    EventImg,
} from '../components/styles/Homepage.styled'


export const Events = () => {
    const { t } = useTranslation('translation');

    return (
        <>

            <Header />
            <ContentPage>
                <Container>
                    <div>
                    <h2>{t('events.event1.title')}</h2>
                    <p>{t('events.event1.intro')}</p>
                    <p><b>{t('events.event1.text1')}:</b>&nbsp;{t('events.event1.desc1')}</p>
                    <p><b>{t('events.event1.text2')}:</b>&nbsp;{t('events.event1.desc2')}</p>
                    <p><b>{t('events.event1.text3')}:</b>&nbsp;{t('events.event1.desc3')}</p>
                    <p><b>{t('events.event1.text4')}:</b>&nbsp;{t('events.event1.desc4')}</p>
                    <p>{t('events.event1.text5')}</p>
                    </div>
                    <EventImg>
                        <img src="/images/pexels-rdne-8500303.jpg" alt="" />
                    </EventImg>
                    <Divider/>
                    <div>
                        <h2>{t('events.event2.title')}</h2>
                        <p>{t('events.event2.text1')}</p>
                        <p>{t('events.event2.text2')}</p>
                        <p>{t('events.event2.text3')}</p>
                    </div>
                    <EventImg>
                        <img src="/images/pexels-alexander-mass-748453803-20811234.jpg" alt="" />
                    </EventImg>
                    <Divider/>
                    <div>
                        <h2>{t('events.event3.title')}</h2>
                        <p>{t('events.event3.text1')}</p>
                        <p>{t('events.event3.text2')}</p>
                        <p>{t('events.event3.text3')}</p>
                        <p>{t('events.event3.text4')}</p>
                        <p>{t('events.event3.text5')}</p>
                    </div>
                    <EventImg>
                        <img src="/images/pexels-ketut-subiyanto-4246266.jpg" alt="" />
                    </EventImg>
                </Container>
            </ContentPage>
        </>
    )
}
