import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'
import {useTranslation} from "react-i18next";

export const AboutUs = () => {
    const { t } = useTranslation('translation');
    return (
        <>
            <Header />
            <ContentPage>
                <Container>
                    <div className="about-us-container">
                    <h1>{t('about.title')}</h1>
                    <p style={{fontSize:17}}>{t('about.text1')}</p>
                    <p style={{fontSize:17}}>{t('about.text2')}</p>
                    </div>
                    </Container>
            </ContentPage>
        </>
    )
}
