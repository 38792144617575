import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import {
    StyledFooter,
    Copyright,
    FooterNavRow,
    FooterNavCol
} from './styles/Footer.styled'

interface Item {
    title: string
    links: NavLink[]
}

interface NavLink {
    name: string
    url: string
}

const Footer = () => {
    const { t } = useTranslation('translation')

    const nav: Item[] = [
        {
            title: t('common.quick links'),
            links: [
                {
                    name: t('common.About'),
                    url: '/about_us',
                },
                { name: t('common.Services'), url: `/terms_of_service` },
                { name: t('common.Energy'), url: `/energy-savings` },
                { name: t('common.Home'), url: `/home` },
                { name: t('common.Community'), url: `/community` },
                { name: t('common.Events'), url: `/events` },
            ],
        },
        {
            title: t('common.social media'),
            links: [
                { name: 'Facebook@SuConexion', url: 'https://www.facebook.com/SuConexionUSA/' },
                { name: 'Instagram:@su_conexion', url: 'https://www.instagram.com/su_conexion/' },
                { name: 'TikTok:@su.conexion', url: 'https://www.tiktok.com/@su_conexion' },
                { name: 'WhatsApp: 833-783-6522', url: '#', },
            ],
        },
    ]

    return (
        <StyledFooter>
            <Container>
                <FooterNavRow>
                    <FooterNavCol className="sn-contact-us">
                        <h6>{t('common.contact us')}</h6>
                        <div>2616 S Loop West, Suite 330, Houston, TX 77054</div>
                        <div>hola@suconexion.com</div>
                        <div>833-783-6522</div>
                    </FooterNavCol>
                    {nav.map((val, idx) => (
                        <FooterNavCol style={{flex:'1'}} key={idx}>
                            <h6>{val.title}</h6>
                            {val.links.map((item, itemIdx) => {
                                return (
                                    <div key={itemIdx}>
                                        <a href={item.url} rel="noreferrer">
                                            {item.name}
                                        </a>
                                    </div>
                                )
                            })}
                        </FooterNavCol>
                    ))}
                </FooterNavRow>
                <FooterNavRow className="custom-container">
                    <img onClick={()=>{window.open(' https://www.eiqdigital.com/')}} style={{cursor:'pointer'}} src="/images/eiqdigital-logo.png" alt="eiqlogo"/>
                </FooterNavRow>
                <Copyright className="custom-container">
                    <p>©2022 Arowwai Industries. All Rights Reserved.</p>
                    <p className='footer-link'>
                        <a href='/terms_of_service' style={{color:'#121518',textDecoration: 'none', padding:"0 5px"}}>Terms of use </a>
                        | 
                        <a href='/privacy_policy' style={{color:'#121518',textDecoration: 'none', padding:"0 5px"}}>Privacy Environmental Policy</a>
                    </p>
                </Copyright>
            </Container>
        </StyledFooter>
    )
}

export default Footer
