import { useTranslation } from 'react-i18next'
import { Container, Form } from 'react-bootstrap'
import { ValidateAddress } from './validate_address'
import Header from './components/header'
import { FormError } from './components/enrollment/FormError'
import {
    HomeTopSectionInner,
    HomeTopSectionWrapper,
    HomepageTopSection,
    HomepageTopSectionMain,
    SectionContent,
    SectionImg,
    SectionRow,
    UserDetailsForm
    // PartnerRow,
    // HomeSection
} from './components/styles/Homepage.styled'
import { toast } from 'react-toastify';
import { Button, LinkAsButton } from "./components/styles/Button.styled";
import React, { useState } from "react";
import { fetchMailChimp } from './api/api'

export const Homepage = () => {
    const [errorFields, setErrorFields] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState<any>({
        name: { type: 'text', value: '' },
        email: { type: 'text', value: '' },
    })
    const { t } = useTranslation('translation');

    const handleChangeInput = (evt: any) => {
        const { value, name } = evt.target

        let newInputValue = Object.assign({}, inputValue)
        newInputValue[name as any].value = value
        setInputValue(newInputValue)
    }
    const onSubmit = () => {
        if (loading) return
        let newErrorFields: any = {}
        if (!(inputValue.name && inputValue.name.value.trim() != '')) {
            newErrorFields['name'] = t('homepage.The field is required')
        }
        const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        if (!pattern.test(inputValue.email.value)) {
            newErrorFields['email'] = t('homepage.Invalid emaill address format')
        }
        setErrorFields(newErrorFields)
        if (JSON.stringify(newErrorFields) != '{}') {
            return
        }
        setLoading(true)
        fetchMailChimp(inputValue.name.value, inputValue.email.value).then((res: any) => {
            setLoading(false)
            if (res.status === 1) {
                toast.success(`${t(res.message)}`);
                let newInputValue = Object.assign({}, inputValue);
                newInputValue.name.value = '';
                newInputValue.email.value = '';
                setInputValue(newInputValue)
            } else {
                toast.error(`${t(res.message)}`);
            }
        })
    }

    return (
        <>
            <HomeTopSectionWrapper>
                <HomeTopSectionInner>
                    <Header />
                    <Container className='hero-section-wrapper'>
                        <HomepageTopSection>
                            <HomepageTopSectionMain>
                                <h1>{t('homepage.save at home')}</h1>
                                <div className='sub-heading-wrapper'><p>{t('homepage.welcome to suconexion')}</p></div>
                                <ValidateAddress />
                                <div className='contact-us-text'> <p>{t('homepage.call representative')}</p></div>
                            </HomepageTopSectionMain>
                        </HomepageTopSection>
                    </Container>


                    <Container className="sn-rep-list-wrapper">
                        <div>
                            <div className="sn-pages-list">
                                <div>
                                    <ul className="nav-list">
                                        <li className="active"><a href="https://www.suconexion.com/">{t('common.Energy')}</a></li>
                                        <li><a href="/home">{t('common.Home')}</a></li>
                                        <li><a href="/community">{t('common.Community')}</a></li>
                                        <li><a href="/events">{t('common.Events')}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="sn-rep-list-section">
                                <div>
                                    <img src="/images/rep_logos/269-2.jpg" alt=""
                                    />
                                    <LinkAsButton variant="secondary" href="https://goodcharlie.com/" target="_blank">{t('homepage.Learn More')}</LinkAsButton>
                                    {/*<a href='https://www.suconexion.com/'>Learn More</a>*/}
                                </div>
                                <div>
                                    <img src='/images/rep_logos/230-2.jpg' alt=""
                                    />
                                    <LinkAsButton variant="secondary" href="https://cleanskyenergy.com/" target="_blank">{t('homepage.Learn More')}</LinkAsButton>
                                </div>
                                <div>
                                    <img src='/images/rep_logos/160-2.jpg' alt="" />
                                    <LinkAsButton variant="secondary" href="https://paylesspower.com/" target="_blank">{t('homepage.Learn More')}</LinkAsButton>
                                </div>
                            </div>
                        </div>
                    </Container>


                    <Container>
                        <div className="best-offers-save-wrapper">
                            <h2>{t('homepage.Best Offers')}</h2>
                        </div>
                    </Container>
                </HomeTopSectionInner>
            </HomeTopSectionWrapper>


            <div className="container-fluid best-offers-main-wrapper">
                <Container>
                    <div className="best-offers-wrapper">
                        <div className="content-best-offers-section">
                            <p>{t("homepage.your ally")}</p>
                            <p>{t("homepage.latest offers")}</p>
                        </div>
                        <div className="img-best-offers-section">
                            <div className="best-offers-images top"><img src="/images/hp-img-3.jpg" alt="" /></div>
                            <div className="best-offers-images"><img src="/images/hp-img-2.jpg" alt="" /></div>
                            <div className="best-offers-images"><img src="/images/hp-img-1.jpg" alt="" /></div>
                        </div>
                    </div>
                </Container>
            </div>



            <div className="container">
                <SectionRow $reverse>
                    <SectionContent className="content-section margin-left">
                        <h3 style={{ color: '#121518' }}>{t('homepage.change company')}</h3>
                        <h4 style={{ color: '#fff' }}>{t('homepage.smart shopping')}</h4>
                        <p>{t('homepage.effective ways')}</p>
                        <p className="read-more-links">
                            <LinkAsButton variant="secondary" href="/smart-shopping">{t('homepage.read more')}</LinkAsButton></p>
                    </SectionContent>

                    <SectionImg>
                        <img src="/images/hp-img-4.jpg" alt="" />
                    </SectionImg>
                </SectionRow>
            </div>
            <div className="container">
                <SectionRow>
                    <SectionContent style={{ backgroundColor: "#6bacc3" }} className="content-section margin-right">
                        <h3>{t('homepage.reduce energy')}</h3>
                        <h4 style={{ color: '#fff' }}>{t('homepage.energy savings')}</h4>
                        <p>{t('homepage.Transitioning to new source')}</p>
                        <p className="read-more-links"> <LinkAsButton variant="secondary" href="/energy-savings">{t('homepage.read more')}</LinkAsButton></p>
                    </SectionContent>

                    <SectionImg>
                        <img src="/images/hp-img-5.jpg" alt="" />
                    </SectionImg>
                </SectionRow>
            </div>


            <div className="container-fluid" style={{ backgroundColor: '#eea640' }}>
                <Container className="validate-address-section">
                    <h2 style={{ paddingBottom: "12px" }}>{t('homepage.find best plan')}</h2>
                    <ValidateAddress />
                </Container>
            </div>

            <Container>
                <div className="more-strategies-form-wrapper">
                    <h4 style={{ fontWeight: "bold" }}>{t('homepage.discover new strategies')}</h4>
                    <p>{t('homepage.sign up')}</p>
                </div>
                <UserDetailsForm>
                    <Form>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                name="name"
                                maxLength={50}
                                placeholder={t('homepage.Name')}
                                value={inputValue.name.value}
                                onChange={handleChangeInput}
                                disabled={loading}
                            />
                        </Form.Group>
                        <FormError message={errorFields['name']} />

                        <Form.Group>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder={t('homepage.Email')}
                                maxLength={150}
                                value={inputValue.email.value}
                                onChange={handleChangeInput}
                                disabled={loading}
                            />
                        </Form.Group>
                        <FormError message={errorFields['email']} />
                        <div style={{ textAlign: 'center', marginTop: '24px' }}>
                            <Button disabled={loading} type="button" onClick={() => { onSubmit() }}>
                                {t('homepage.submit')}
                            </Button>
                        </div>
                    </Form>
                </UserDetailsForm>
                <div className="contact-us-text">
                    <p className="footer-text">{t('homepage.call representative')}</p>
                </div>
                <div style={{ margin: '68px auto 16px auto' }}>
                    <a href="https://www.suconexion.com/"><img src="/images/logo.png" alt="SuConexion" height="30" /></a>
                </div>
            </Container>
        </>
    )

}
