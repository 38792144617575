import { Link } from 'react-router-dom'
import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

export const TOS = () => {
  const { t } = useTranslation('translation');
  return (
    <>
      <Header />
      <ContentPage>
        <Container>
          <h1>{t('tos.title')}</h1>
          <p>
            {t('tos.intro')}
          </p>
          <p>
            {t('tos.intro2')}
          </p>
          <p>
            {t('tos.privacy_policy_info')} <Link to="/privacy_policy">{t('tos.privacy_policy_link')}</Link>.
            {t('tos.privacy_policy_info2')}{' '}
            <Link to="/privacy_policy">{t('tos.privacy_policy_link')}</Link>.
          </p>
          <h2>{t('tos.service_description_title')}</h2>
          <p>
            {t('tos.service_description')}
          </p>
          <p>
            {t('tos.energy_plan_info')}
          </p>
          <p>
            {t('tos.contractual_relationship')}
            <br />
            {t('tos.member_agreement')}
            <br />
            {t('tos.member_agreement2')}

          </p>
          <h2>{t('tos.license_title')}</h2>
          <p>
            {t('tos.license')}
          </p>
          <h2>{t('tos.termination_license_title')}</h2>
          <p>
            {t('tos.termination_license')}
          </p>
          <h2>{t('tos.presentation_compensation_title')}</h2>
          <p>
            {t('tos.presentation_compensation')}

          </p>
          <h2>{t("tos.general_prohibitions_title")}</h2>
          <p>
            {t("tos.general_prohibitions.section_1")}
            <br />
            {t("tos.general_prohibitions.section_2")}
            <br />
            {t("tos.general_prohibitions.section_3")}
            <br />
            {t("tos.general_prohibitions.section_4")}
            <br />
            {t("tos.general_prohibitions.section_5")}
            <br />
            {t("tos.general_prohibitions.section_6")}
            <br />
            {t("tos.general_prohibitions.section_7")}
            <br />
            {t("tos.general_prohibitions.section_8")}
            <br />
            {t("tos.general_prohibitions.section_9")}
            <br />
            {t("tos.general_prohibitions.section_10")}
          </p>
          <h2>{t("tos.linking_policies_title")}</h2>
          <p>
            {t("tos.linking_policies.section_1")}
            <br />
            {t("tos.linking_policies.section_2")}
          </p>
          <h2>{t("tos.linking_policies.no_warranty.section_1")}</h2>
          <p>
            {t("tos.linking_policies.no_warranty.section_2")}
            <br />
            {t("tos.linking_policies.no_warranty.section_3")}
            <br />
            {t("tos.linking_policies.no_warranty.section_4")}
          </p>
          <h2>{t("tos.limitation_of_liability.section_1")}</h2>
          <p>
            {t("tos.limitation_of_liability.section_2")}
          </p>
          <h2>{t("tos.indemnification.section_1")}</h2>
          <p>
            {t("tos.indemnification.section_2")}
          </p>
          <h2>{t("tos.copyright.section_1")}</h2>
          <p>
            {t("tos.copyright.section_2")}
          </p>
          <h2>{t("tos.choice_of_law.section_1")}</h2>
          <p>
            {t("tos.choice_of_law.section_2")}
            <br />
            {t("tos.choice_of_law.section_3")}
          </p>
          <h2>{t("tos.arbitration_agreement.section_1")}</h2>
          <p>
            {t("tos.arbitration_agreement.section_2")}
          </p>
          <h2>{t("tos.limitation_of_actions.section_1")}</h2>
          <p>
            {t("tos.limitation_of_actions.section_2")}
          </p>
          <h2>{t("tos.notice_of_changes.section_1")}</h2>
          <p>
            {t("tos.notice_of_changes.section_2")}
          </p>
          <h2>{t("tos.entire_agreement.section_1")}</h2>
          <p>
            {t("tos.entire_agreement.section_2")}
          </p>
          <h2>{t("tos.severability.section_1")}</h2>
          <p>
            {t("tos.severability.section_2")}
          </p>
        </Container>
      </ContentPage>
    </>
  )
}
