import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import React from "react";


export const EnergySavings = () => {
    const { t } = useTranslation('translation');

    return (
        <>

            <Header />
            <ContentPage>
                <Container>
                    <h1>{t('energySavings.heading')}</h1>
                    <p>{t('energySavings.subheading')}</p>
                    <ol>
                        <li><b>{t('energySavings.text1Intro')}</b>
                         <p>{t('energySavings.text1Desc')}</p></li>
                        <li><b>{t('energySavings.text2Intro')}</b><p>{t('energySavings.text2Desc')}</p></li>
                        <li><b>{t('energySavings.text3Intro')}</b><p>{t('energySavings.text3Desc')}</p></li>
                        <li><b>{t('energySavings.text4Intro')}</b><p>{t('energySavings.text4Desc')}</p></li>
                        <li><b>{t('energySavings.text5Intro')}</b><p>{t('energySavings.text5Desc')}</p></li>
                        <li><b>{t('energySavings.text6Intro')}</b><p>{t('energySavings.text6Desc')}</p></li>
                    </ol>
                    <p><b>{t('energySavings.conclusion')}</b></p>
                    <p>{t('energySavings.conclusionText')}</p>

                </Container>
            </ContentPage>
        </>
    )
}
