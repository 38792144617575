import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import React from "react";


export const HomeBlog = () => {
    const { t } = useTranslation('translation');
    const pageContent = t('homeBlog', { returnObjects: true });

    return (
        <>

            <Header />
            <ContentPage>
                <Container>
                        {pageContent.map((content: any, index: number) => (
                            <div key={index} className="blog-section-wrapper">
                                <h1>{t(content.heading)}</h1>
                                <p>{t(content.desc1)}</p>
                                <p>{t(content.desc2)}</p>
                            </div>
                        ))}
                </Container>
            </ContentPage>
        </>
    )
}
