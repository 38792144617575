import styled from 'styled-components';

interface RowProps {
    $reverse?: boolean;
}

export const HomeTopSectionWrapper = styled.div`
    
`

export const HomeTopSectionInner = styled.div`
   
`

export const SectionRow = styled.div<RowProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 0;
    @media (min-width: 768px) {
      flex-direction: ${props => props.$reverse ? 'row-reverse' : 'row'};
       padding: 44px 0;
       justify-content: center;
    }
     h2 {
        font-size: 44px;
        @media (max-width: 768px) {
        font-size: 24px;
    }
    }
`

export const QuoteContent = styled.div`
    text-align: center;
    padding: 100px 0;
    display: flex;
    justify-content: center;

    p {
        max-width: 550px;
        font-size: 24px;
        font-weight: 600;
        position: relative;
        padding: 0 20px;
    }

    p::before {
        content: ' ';
        display: inline-block;
        width: 40px;
        height: 38px;
        background: url('/images/quote-s.png') 0 0 no-repeat;
        position: absolute;
        top: -30px;
        left: -10px;
        opacity: .9;
    }

    p::after {
        content: ' ';
        display: inline-block;
        width: 40px;
        height: 38px;
        background: url('/images/quote-e.png') 0 0 no-repeat;
        background-size: 50%;
        position: absolute;
        bottom: -20px;
        right: -10px;
        opacity: .5;
    }
`

export const SectionContent = styled.div`
    background-color: #eea640;
    h2 {
        margin-bottom: 24px;
    }
    h3 {
        font-size: 1.25rem;
        font-weight: bold;
          @media (min-width: 767px) {
         font-size: 1.1rem;
    }
      @media (min-width: 992px) {
         font-size: 1.5rem;
    }
         @media (min-width: 1200px) {
         font-size: 1.7rem;
         padding: 0 4px;
    }
        
    }
    h4 {
    font-weight: bold;
    font-size: 14px;
   @media (min-width: 768px) {
    font-size: 18px;
   }
    }

    p {
        font-size: 11px;
        font-weight: bold;
         @media (min-width: 375px) {
        font-size: 12px;
    }
    @media (min-width: 767px) {
       padding: 8px;
        height: auto;
        flex: 0 75%;
         font-size: 14px;
    }
       @media (min-width: 1200px) {
        flex: 0 70%;
        font-size: 16px;
    }
     @media (min-width: 1440px) {
        flex: 0 70%;
        font-size: 18px;
    }
`

export const SectionImg = styled.div`
     width: 100%;
     text-align: center;
     margin-top: 12px;
    img {
        width: 300px;
        height: auto;
        box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
         @media (min-width: 1200px) {
            width: 500px;
            filter: drop-shadow(9px 10px 6px);
        }
    }
    @media (min-width: 768px) {
        flex: 0 25%;
    }
     @media (min-width: 1200px) {
        flex: 0 30%;
    }
`


export const EventImg = styled.div`
     width: 100%;
     text-align: center;
     margin-top: 12px;
    img {
        width: 300px;
        height: auto;
         @media (min-width: 1200px) {
            width: 500px;
        }
    }
    @media (min-width: 768px) {
        flex: 0 25%;
    }
     @media (min-width: 1200px) {
        flex: 0 30%;
    }
`

export const HomeSection = styled.div`
    background-color: #fff;
    text-align: center;
    padding-top: 64px;
    padding-bottom: 32px;
`

export const PartnerRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0;
    justify-content: center;
    align-items: center;

    img {
        width: 124px;
        margin: 24px 20px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: .6;
    }
`

export const HomepageTopSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 12px;

    @media (min-width: 767px) {
        flex-direction: column;
        padding: 32px 100px;
    }
`

export const HomepageTopSectionMain = styled.div`
    width: 100%;
    text-align: center;

    h1 {
        color: #fff;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1;
        text-shadow: #000 1px 0 8px;
        margin-bottom: 20px;
        @media (min-width: 767px) { 
           font-size: 1.2rem;
          }
          @media (min-width: 1200px) { 
           font-size: 1.8rem;
          }
    }
    .sub-heading-wrapper {
    width: 100%;
    text-align: -webkit-center;
    margin-bottom: 32px;
        p {
           font-size: .8rem;
            line-height: 1.2;
            color: #030505;
            text-shadow: #000 1px 0 0px;
             @media (min-width: 767px) {
               font-size: 1rem;
             }
        @media (min-width: 992px) {
        font-size: 1.3rem;
                max-width: 750px;
                 @media (min-width: 1200px) {
            font-size: 1.5rem;
            max-width: 898px;
            }
            }
        }
     }
     .contact-us-text {
     p {
        color: #fff;
        text-shadow: #000 1px 0 4px;
     }
     }
    }

    @media (min-width: 991px) {
        h1 {
            font-size: 45px;
        }
    }

    @media (min-width: 767px) {
        width: 100%;
    }
`

export const HomepageTopSectionImg = styled.div`
    display: flex;
    flex: 1;
    justify-content: end;

    img {
        width: 90%;
        max-width: 640px;
    }

    @media (min-width: 767px) {
        display: none;
    }
`
export const UserDetailsForm = styled.div`
    display: block;
    width: 290px;
    margin: auto;
     @media (min-width: 767px) {
        width: 350px;
    }
    form {
    div {
    margin: 12px auto;
    }
    }
    
    Button {
    padding: 12px 60px;
    
    &:hover {
    box-shadow: -6px 13px 27px #266585;
    }
    }
    
`
