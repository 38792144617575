import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Container } from 'react-bootstrap';
import { css } from '@emotion/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../i18n';

const Header = () => {
    const { t } = useTranslation("translation");
    const [ showNav, setShowNav ] = useState(false);

    const onClickLanguageChange = (e: any) => {
        const changed = e.target.ariaPressed;
        let language = 'en';
        if(changed === "true") {
            language = 'en';
            e.target.setAttribute('aria-pressed', 'false');
        } else {
            language = 'es';
            e.target.setAttribute('aria-pressed', 'true');
        }
        i18n.changeLanguage(language); //change the language
    }

    const nav = [
        { label: t('common.About'), url: "/about_us" },
        { label: t('common.Services'), url: "/terms_of_service" },
        { label: '833-783-6522', url: "tel:1-833-783-6522" },
    ]
    return (
        <>
            <Container>
                <div className={css`
                    padding: 24px 0;
                `}>
                    <div className = {css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 2px solid #000;
                    padding-bottom: 8px;
                    a {
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                    span {
                        margin-left: 4px;
                        font-size: 12px;
                        color: #999;
                    }
                `}>
                        <div className="sn-img-lang-wrapper">
                    <a href="/"><img src="/images/logo.png" alt="SuConexion" height="30" /></a>
                        <button onClick={onClickLanguageChange} aria-pressed="false">{t('common.lang')}</button>
                        </div>
                    <div className="d-none align-items-center justify-content-center d-md-flex d-lg-flex d-xl-flex">
                        {
                            nav.map((val, idx) => (
                                <a href={val.url} key={idx} className="nav">{val.label}</a>
                            ))
                        }
                    </div>
                    {
                        <div className="d-block d-md-none">
                            <button className={css`
                                border: 0 none;
                                background: transparent;
                                cursor: pointer;
                            `} onClick={() => {setShowNav(!showNav);}}>
                                <FontAwesomeIcon icon="bars" style={{fontSize:20,color:'#666'}} />
                            </button>
                        </div>
                    }
                </div>
                </div>
            </Container>
            {
                showNav &&
                <div className={css`
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: #fff;
                    z-index: 999;
                `}>
                    <div className={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                    `}>
                        {
                            nav.map((val, idx) => (
                                <a href={val.url} key={idx} className="nav" style={{marginTop:20,marginBottom:20}}>{val.label}</a>
                            ))
                        }
                    </div>

                    <button className={css`
                        display: inline-block;
                        position: absolute;
                        top: 16px;
                        right: 10px;
                        border: 0 none;
                        background: transparent;
                        cursor: pointer;
                        z-index: 100;
                    `} onClick={() => setShowNav(false)}>
                        <FontAwesomeIcon icon="times-circle" style={{color:'#aaa',fontSize:36}} />
                    </button>
                </div>
            }
        </>
    )
}

export default Header
