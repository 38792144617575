import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import React from "react";


export const SmartShopping = () => {
    const { t } = useTranslation('translation');

    return (
        <>

            <Header />
            <ContentPage>
                <Container>
                    <h1>{t('smartShopping.heading')}</h1>
                    <p>{t('smartShopping.subheading')}</p>
                    <ol>
                        <li><b>{t('smartShopping.text1Intro')}</b>&nbsp; {t('smartShopping.text1Desc')}</li>
                        <li><b>{t('smartShopping.text2Intro')}</b>&nbsp; {t('smartShopping.text2Desc')}</li>
                        <li><b>{t('smartShopping.text3Intro')}</b>&nbsp; {t('smartShopping.text3Desc')}</li>
                        <li><b>{t('smartShopping.text4Intro')}</b>&nbsp; {t('smartShopping.text4Desc')}</li>
                        <li><b>{t('smartShopping.text5Intro')}</b> &nbsp;{t('smartShopping.text5Desc')}</li>
                        <li><b>{t('smartShopping.text6Intro')}</b>&nbsp; {t('smartShopping.text6Desc')}</li>
                        <li><b>{t('smartShopping.text7Intro')}</b>&nbsp; {t('smartShopping.text7Desc')}</li>
                    </ol>
                    <p>{t('smartShopping.conclusion')}</p>

                </Container>
            </ContentPage>
        </>
    )
}
