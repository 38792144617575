import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en/translation.json";
import es from "./locales/es/translation.json";
import enenrollment from "./locales/en/enrollment.json";
import esenrollment from "./locales/es/enrollment.json";
import planEN from "./locales/en/plan.json";
import planES from "./locales/es/plan.json";
import usageEN from "./locales/en/usage.json";
import usageES from "./locales/es/usage.json";

const detectionOptions = {
    caches: ['cookie'],
    cookieSameSite: 'strict',
    lookupQuerystring: 'lang', // default is lng
    lookupCookie: 'next-i18next',
    order: ['querystring', 'cookie', 'header'], // IMPORTANT
}

export const resources = {
    en: {
        translation: en,
        enrollment:enenrollment,
        plan: planEN,
        usage: usageEN,
    },
    es: {
        translation: es,
        enrollment:esenrollment,
        plan: planES,
        usage: usageES,
    }
}

i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
    resources,
    defaultNS: "common",
    fallbackLng: "en"
});
export default i18n;
