import styled from 'styled-components';

interface ButtonProps {
    variant?: string;
    $md?: boolean;
    disabled?: boolean;
}
interface LinkAsButtonProps {
    href: string;
    variant?: string;
    $md?: boolean;
    target?: string;
    className?: string;
}

export const Button = styled.button<ButtonProps>`
    background: ${props => props.disabled ? (props.variant === 'secondary' ? '#fefefe' : '#C5C6CD') : (props.variant === 'secondary' ? '#fefefe' : '#fff')};
    color: ${props => props.variant === 'secondary' ? '#266585' : '#000'};
    border: ${props => props.variant === 'secondary' ? '1px solid #eee' : '0 none'};
    font-weight: 600;
    border: 1px solid #266585;
    font-size: ${props => props.$md ? '14px' : '16px'};
    padding: ${props => props.$md ? '8px 16px' : '12px 32px'};
    border-radius: 24px;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    transition: opacity .3s;
    :hover{
        opacity: ${props => props.disabled ? '1' : '.85'};
        box-shadow: '-6px 13px 27px #266585';
    }
`

export const LinkAsButton = styled.a<LinkAsButtonProps>`
    background: ${props => (props.variant === 'secondary' ? '#fefefe' : '#fff')};
    color: ${props => props.variant === 'secondary' ? '#0c0f11' : '#000'};
    border: ${props => props.variant === 'secondary' ? '1px solid #eee' : '0 none'};
    font-weight: 600;
    border: 1px solid #000;
    font-size: ${props => props.$md ? '14px' : '16px'};
    padding: ${props => props.$md ? '8px 16px' : '12px 32px'};
    border-radius: 24px;
    cursor: pointer;
    transition: opacity .3s;
    text-decoration: none;
     &:hover{
         background: ${props => (props.variant === 'secondary' ? '#0c0f11' : '#0c0f11')};
         color: ${props => props.variant === 'secondary' ? '#fff' : '#000'};
         box-shadow: -6px 13px 27px #266585;
    }
`

