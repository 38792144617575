import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { Button } from "../components/styles/Button.styled";
import { useTranslation } from "react-i18next";
import { EnrollmentStepWrapper } from "../components/styles/Enrollment.styled";
import qs from "qs";
import { useEffect, useState } from "react";
import { fetchPayment } from "../api/enrollment";
import { LoadingRow } from "../components/loading-row";

export const PaymentStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState();
  const { sign, enrollment, confirmation } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { t } = useTranslation("enrollment");
  
  useEffect(() => {
    if (sign && enrollment && confirmation) {
      //TO do call api
      fetchPayment(sign, enrollment, confirmation).then((res) => {
        setLoading(false);
        if (res && res.status) {
          setPaymentStatus(res.status);
        }
      });
    }
  }, [sign, enrollment, confirmation]);

  return (
    <Container>
      <EnrollmentStepWrapper>
        {loading && <LoadingRow/>}
        {!loading && paymentStatus && (
          <div className="pt-4">
            <div className="text-center">
              <h1>{t("Congratulations")}!</h1>
              <p>
                {t(
                  "Your application has been approved! Please wait for the email confirmation from the Retail Energy Provider (REP) including the plan details and the next steps. Thank you for choosing SuConexion."
                )}
              </p>
              <div className="mt-5">
                <Button
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  {t("Close the Window")}
                </Button>
              </div>
            </div>
          </div>
        )}
        {(!loading && !paymentStatus) &&
          <div className="pt-4">
            <div className="text-center">
              <h1>{t("Pending Verification")}!</h1>
              <p>
                {t(
                  "Sorry, we couldn't detect a successful deposit payment. Please wait a few minutes and check again, or try refreshing the page. Thank you for choosing SuConexion."
                )}
              </p>
              <div className="mt-5">
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  {t("Refresh Page")}
                </Button>
              </div>
            </div>
          </div>
        }
      </EnrollmentStepWrapper>
    </Container>
  );
};
